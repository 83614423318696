export const WORDS = [
  'dunno',
  'tekan',
  'stylo',
  'shiok',
  'walao',
  'chope',
  'kiasu',
  'bojio',
  'relak',
  'lepak',
  'aiyah',
  'cheem',
  'leceh',
  'dowan',
  'boleh',
  'solid',
  'arrow',
  'tapao',
  'mabok',
  'nabeh',
  'steam',
  'balik',
  'sibeh',
  'tuang',
  'manja',
  'kilat',
  'whack',
  'habis',
  'suaku',
  'havoc',
  'shack',
  'tulan',
  'donch',
  'siong',
  'teruk',
  'bodoh',
  'cabut',
  'rabak',
  'buaya',
  'koyak',
  'macam',
  'tiong',
  'hoseh',
  'mafan',
  'tekan',
  'salah',
  'jelak',
  'ngeow',
  'aiyoh',
  'botak',
  'drama',
  'rojak',
  'tahan',
  'gabra',
  'makan',
  'hanor',
  'tikam',
  'aiseh',
  'koyok',
  'bedek',
  'kiasi',
  'prata',
  'pakat',
  'gelek',
  'geena',
  'sakar',
  'oredi',
  'abang',
  'sudah',
  'bagus',
  'spiak',
  'izzit',
  'walau',
  'jalan',
  'power',
  'ketuk',
  'nenek',
  'otang',
  'notti',
  'ehsai',
  'ahpek',
  'sedap',
  'bogay',
  'hantu',
  'geero',
  'eeyer',
  'extra',
  'anzua',
  'mambo',
  'kacau',
  'kakak',
  'geram',
  'argly',
  'saman',
  'dungu',
  'ehsai',
  'fetch',
  'dunno',
  'stylo',
  'bohew',
  'joget',
  'gasak',
  'boomz',
  'pisai',
  'aimai',
  'ahsoh',
  'getai',
  'laksa',
  'lampa',
  'katik',
  'khoon',
  'kopio',
  'lohei',
  'pasal',
  'taiko',
  'aizai',
  'orbit',
  'kopic',
  'cucuk',
  'choot',
  'kabut',
  'pecah',
  'laobu',
  'jibra',
  'thani',
  'busuk',
  'aisui',
  'encik',
  'berak',
  'bohua', 
  'tiobo',
  'tuaki',
  'buang',
  'debus',
  'heaty',
  'wuhua',
  'yalor',
  'pasat',
  'sianz',
  'bolat',
  'pasar',
  'piang',
  'sikit',
  'cukup',
  'satay',
  'susah',
  'hosay',
  'mamak',
  'pedas',
  'tcher',
  'cikgu',
  'aibia',
  'womit',
  'gadai',
  'masak',
  'pusat',
  'kichi',
  'dabao',
  'rosak',
  'lemak',
  'ahboy',
  'pukul',
  'siuye',
  'sugee',
  'laiah',
  'ahkau',
  'ketok',
  'hosei',
  'lolex',
  'eskew',
  'mader',
  'hanna',
  'ahger',
  'lumpa',
  'achar',
  'kapuk',
  'angmo',
  'jambu',
  'assam',
  'bulat',
  'issit',
  'butoh',
  'chiku',
  'haeko',
  'relac',
  'petai',
  'humji',
  'datuk',
  'kapok',
  'pakay',
  'bohiu',
  'busok',
  'kawan',
  'rotan',
  'thiam',
  'bluff',
  'wakao',
  'bohji',
  'cocok',
  'slack',
  'uncle',
  'eeyur',
  'homia',
  'hiong',
  'lasai',
  'dhobi',
  'walan',
  'tiloh',
  'nabei',
  'eysai',
  'zuobo',
  'kotek',
  'tiabo',
  'nonid',
  'ngiao',
  'kiang',
  'aisee',
  'slime',
  'filem',
  'juang',
  'swaku',
  'tehsi',
  'pergi',
  'aisay',
  'dulan',
  'kapoh',
  'jakun',
  'limbu',
  'ahkor',
  'unker',
  'zaisi',
  'chiak',  
 ]