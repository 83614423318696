export const WORD_DEFINITIONS = new Map([
	['MANJA', 'Go MANJA your loved ones todeh!'],
	['KILAT', 'This Word-leh! game is excellent. So KILAT!'],
	['WHACK', 'Just play for fun lah, anyhow WHACK!'],
	['SUAKU', 'Who never heard of WORD-LEH? So SUAKU!'],
	['HAVOC', 'Last time very havoc. Nowadays stay home play WORD-LEH!'],
	['SHACK', 'Wah, so tired. Play WORD-LEH until SHACK!'],
	['TULAN', 'Cannot guess the answer, angry liao. Sibeh TULAN!'],
	['DONCH', 'DONCH worry. Tomolo can play again!'],
	['SIONG', 'Todeh game very hard. SIONG ah!'],
	['TERUK', 'Todeh game oso hard. TERUK!'],
	['BODOH', 'You not BODOH lah. You vsery clever!'],
	['CABUT', 'Still working? Mai tu liao! Faster CABUT go home play WORD-LEH!'],
	['HABIS', 'Guess 6 times oredi still dunno the WORD-LEH? HABIS lah!'],
	['HOSEH', 'How are you? HOSEH bo?'],
	['MAFAN', 'English grammar so mafan, just spiak Singlish easier!'],
	['SALAH', 'Cannot guess today\'s WORD-LEH, so SALAH!'],
	['JELAK', 'Ate too much pizza, so JELAK!'],
	['NGEOW', 'Dun be NGEOW about the spelling. Could be ngiao too! '],
	['TEKAN', 'Dun TEKAN me if WORD-LEH! got no more 5 letter words liao'],
	['AIYOH', 'Did you know AIYOH is in the OED?'],
	['BOTAK', 'Weather so hot, I want to shave BOTAK.'],
	['ROJAK', 'Singlish is like ROJAK, everything anyhow mix.'],
	['TAHAN', 'Buay TAHAN, cannot take it anymore!'],
	['AISEH', 'AISEH is not Hokkien, it\'s I SAY'],
	['WALAU', 'WALAU - how come Singlish words got different spellings one?'],
	['PRATA', 'Dun flip flop like PRATA ok?'],
	['PAKAT', 'You all PAKAT together to guess the WORD-LEH izzit?'],
	['GEERO', 'GEERO one two tree...'],
	['PECHA', 'Dun go and PECHA lobang and give away the answer ok?'],
	['SPIAK', 'You SPIAK singlish very well!'],
	['OTANG', 'Lend me money can? I OTANG you lah.'],
	['ANZUA', 'ANZUA? What you want?'],
	['EEYER', 'EEYER! So gross!'],
	['NOTTI', 'What a NOTTI boy you are!'],
	['CHIAK', 'That guy skiving at work again, CHIAK chua!'],
	['CHOON', 'CHOON boh? Sure or not?'],
	['EXTRA', 'Why you so EXTRA, one?'],
	['EHSAI', 'Today\'s game EHSAI boh? Can or not?'],
	['FETCH', 'I FETCH you home?'],
	['KETUK', 'Ow! You KETUK my head for what?'],
	['IZZIT', 'Today WORD-LEH very hard IZZIT?'],
	['JALAN', 'Got time to go JALAN-JALAN with me?'],
	['POWER', 'Dun just help with eye POWER lah!'],
	['KETUK', 'Free game ah, I never KETUK you.'],
	['OTANG', 'You help me pay first, I OTANG you.'],
	['DUNGU', 'Today I very blur, like goondu or dungu.'],
	['SAMAN', 'Dun anyhow park your car! You might kena SAMAN.'],
	['KACAU', 'Dun KACAU me when I\'m playing WORD-LEH ok?'],
	['AIMAI', 'Go makan AIMAI?'],
	['BOOMZ', 'My new dress is BOOMZ!'],
	['BOHEW', 'She walked past and BOHEW me!'],
	['PISAI', 'He likes to dig his pisai in the car.'],
	['JOGET', 'The music was so shiok, I JOGET until fall down.'],
	['KOPIO', 'KOPI-O Siew Dai Peng plz!'],
	['KOPIC', 'KOPI-C Gau Kosong plz!'],
	['ORBIT', 'This dress so ORBIT. Who wants to buy?'],
	['TAIKO', 'Just now that goal TAIKO one lah, not skill.'],
	['AIZAI', 'AI ZAI, dun worry!'],
	['PASAL', 'Eh, this thing my PASAL, my business, dun kaypoh ok?'],
	['DEBUS', 'DEBUS! DEBUS! Quickly get off the bus plz.'],
	['CHOOT', 'Dr Gwee CHOOT new Singlish book, go and buy!'],
	['THANI', 'Let\'s go THANI until mabok, drink until drunk!'],
	['TIOBO', 'TIO BO - Correct or not?'],
	['PECAH', 'Dun PECAH lobang, let the cat out of the bag!'],
	['JIBRA', 'Got JIBRA crossing, why you must jaywalk?'],
	['AIBIA', 'He dunno how to relak one. Everyone must also AIBIA!'],
	['TCHER', 'Tcher, I dunno how to do my homework!'],
	['GADAI', 'Unker, Kopi GADAI plz!'],
])